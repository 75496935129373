<template>
	<div>
		<component :is="appMode"></component>
	</div>
</template>
<script>
export default {
	components: {
		super: () => import("./components/Super"),
		staff: () => import("./components/Personal"),
	},
};
</script>